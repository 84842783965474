/* Background color change on scroll */
.navbar {
    width: 100%;
    height: 110px;
    background-color: white;
    
    z-index: 1000;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease-in-out;
    
  
  }
  .navbar-container {
    margin: 0 auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
 

 }

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.logo-image {
  width: 85px;
  height: 85px;
}


.navbar-nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-item {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #1E1E1E;
  text-decoration: none;
  transition: color 0.2s ease, border-bottom 0.2s ease;
  border-bottom: 2px solid transparent;
}

.nav-item:hover, .nav-item.active {
  color: #048BC5;
  border-bottom: 2px solid #048BC5;
}


.nav-item:hover {
  color: #048BC5;
  border-bottom: #048BC5 2px solid;

}
  
  .navbar.scrolled {
    background-color: white;
  }
  
  /* When toggled, show all nav items and buttons below with a white-green background */
  .navbar.toggled {
   
    padding-bottom: 20px;
  }
  
  /* Ensure nav items and buttons are visible when toggled */
  .navbar.toggled .nav-item,
  .navbar.toggled .auth-buttons {
    display: block !important;
    text-align: center;
  }
  .login-button {
    height: 45px;
    width: 135px;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4b5563;
    border: #048BC5 1px solid;
    background-color: white;
    border: 2px solid #048BC5;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-right: 10px;
  }
  
  .login-button:hover {
    background-color: #048BC5;
    border: 2px solid white;
  
  }
  
  .signup-button {
    height: 45px;
    width: 145px;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    background-color: #048BC5;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .signup-button:hover {
      background-color: white;
      border: 2px solid #048BC5;
    border-radius: 10px;
    color: #048BC5;
  
  }
  .logo{
    height: 80px;
    width: 80px;
  }
 
 
  @media (max-width: 992px) {
    .navbar .navbar-collapse {
      display: none;

    }
    .nav-item{
        font-size: 18px;
    }
  
    .navbar .navbar-collapse.show {
      display: block;
      width: 100%;
      background-color: white;
    }
  
    .navbar.toggled .navbar-collapse {
      display: block !important;
    }
  
    .navbar.toggled .nav-item {
      display: block;
      /* padding: 10px 0; */
      width: 100%;
    }
  
    .navbar.toggled .auth-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
      /* align-items: center; */
    }
  }
  