/* Base styles */
.section {
  position: relative;
  width: 100%;
  height: 685px;
}
.search-button {
  background-color: #048BC5; /* Default button color */
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.search-button:hover {
  background-color: #036a94; /* Darker shade on hover */
  transform: scale(1.05); /* Slightly enlarges the button */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adds a soft shadow */
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: -1;
}

.black-background,
.red-background {
  height: 100%;
  width: 50%;
}

.black-background {
  background-color: black;
}

.hero-img {
  height: 685px;
  width: 100%;
  object-fit: cover;
}


.content {
  position: absolute;
  top: 45%;
  left: 37%;
  transform: translate(-50%, -50%);
  text-align: start;
  color: white;
}

.content h1 {
  font-size: 62px;
  font-weight: 600;
  line-height: 80px;
  margin-bottom: 20px;
}

.content p {
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  margin-bottom: 40px;
}

/* Buttons styling */
.buttons {
  margin-bottom: 40px;
  display: flex;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  cursor: pointer;
}

.general-btn {
  background-color: white;
  color: black;
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 0;
}

.emergency-btn {
  background-color: #ff0004;
  color: white;
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: 0;
}

/* Search bar styling */
.search-container {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
 
  width: 100%;
  max-width: 665px;
  height: auto;
  border-radius: 12px;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.dropdown-Service {
  display: flex;
  align-items: center;
  color: black;
  margin-right: 15px;
  cursor: pointer;
}

.Service-span {
  margin-left: 32px;
}

.icon-hero {
  margin-left: 15px;
}

.search-input-container {
  display: flex;
  align-items: center;
  color: black;
  flex: 1 1 auto;
  margin: 10px;
}

.search-input {
  border: none;
  outline: none;
  margin-left: 15px;
  color: black;
  background-color: transparent;
  width: 100%;
}

.search-button {
  border: none;
  cursor: pointer;
  height: 45px;
  width: 160px;
  background-color: #048bc5;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  margin: 10px;
  flex: 1 1 auto;
  max-width: 160px;
}

/* Checkbox styling */
.checkbox-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.checkbox {
  display: flex;
  align-items: center; 
  margin-right: 65px; 
  margin-top: 25px; 
}
.checkbox input {
  margin-right: 5px;
}

.our-recomandation {
  height: 20px; 
  width: 20px; 
}

@media (max-width: 1024px) {
  .content h1 {
    font-size: 50px;
    line-height: 60px;
  }

  .content p {
    font-size: 18px;
    line-height: 28px;
  }

  .search-container {
    width: 100%;
  }

  .search-button {
    width: 140px;
    max-width: 140px;
  }
}

/* For mobile devices */
@media (max-width: 768px) {
  .container {
    height: auto;
  }

  .background {
    flex-direction: column;
  }

  
  .red-background {
    width: 100%;
    height: 100%;
  }

  .content {
    position: static;
    transform: none;
    text-align: center;
    padding: 20px;
  }

  .content h1 {
    font-size: 35px;
    line-height: 45px;
  }

  .content p {
    font-size: 16px;
    line-height: 24px;
  }

  .buttons {
    justify-content: center;
  }

  .search-container {
    flex-direction: column;
    align-items: stretch;
    padding: 15px;
  }

  .dropdown-Service,
  .search-input-container,
  .search-button {
    margin: 10px 0;
    width: 100%;
  }

  .search-button {
    height: 50px;
    font-size: 14px;
    max-width: 100%;
  }

  .checkbox-container {
    justify-content: center;
    margin-top: 10px;
  }

  .checkbox {
    margin: 10px;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .content h1 {
    font-size: 28px;
    line-height: 38px;
  }

  .content p {
    font-size: 14px;
    line-height: 20px;
  }

  button {
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .search-button {
    height: 45px;
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .black-background {
    height: 300px !important;
  }
}