.custom-card {
    width: 250px;
    height: 165px;
    border-radius: 16px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .icon-container {

    width: 75px;
    height: 75px;
    border: 2px solid #007bff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  
    .icon-card {
      color: #007bff;
      height: 35px;
      width: 35px;
    }
  }
  
  /* Text and Line Layout */
  .content-section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  
  .text-content {
    margin-top: 15px;
    flex: 1;
    text-align: left;
   
  }
  .h6-content{
    font-size: 17px;
    color: #007bff;

  }
  
  .line {
    flex: 1; /* Takes up equal space */
    width: 60px;
    height: 2px;
    background-color: #007bff;
    margin-top: 28px;
  }
  