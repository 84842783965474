@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.navbar {
  width: 100%;
  height: 110px;
  background-color: white;
  display: flex;
  align-items: center;
}

.navbar-container {
  margin: 0 auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.logo-image {
  width: 85px;
  height: 85px;
}


.navbar-nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-item {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #1E1E1E;
  text-decoration: none;
  transition: color 0.2s ease;
  border-bottom: transparent 1px solid;
}

.nav-item:hover {
  color: #048BC5;
  border-bottom: #048BC5 1px solid;

}

.nav-item.active {
  color: #048BC5;
  font-weight: bold;
}

.more-button {
  display: flex;
  align-items: center;
  gap: 4px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
  padding: 0;
}

.more-button:hover {
  color: #0ea5e9;
}

.chevron-down {
  width: 16px;
  height: 16px;
}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.login-button {
  height: 45px;
  width: 135px;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
  border: #048BC5 1px solid;
  background-color: white;
  border: 2px solid #048BC5;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-right: 20px;
}

.login-button:hover {
  background-color: #f9fafb;
}

.signup-button {
  height: 45px;
  width: 145px;
  font-size: 0.875rem;
  font-weight: 500;
  color: white;
  background-color: #048BC5;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.signup-button:hover {
  background-color: #048BC5;
}


.hamburger-btn {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburger-btn.open svg {
  transform: rotate(90deg); 
}


@media (max-width: 768px) {
  .nav-item {
    display: none; 
  }

  .login-button {
    display: none;
  }

  .hamburger-btn {
    display: block; /* Show hamburger button */
  }

  .nav.open .nav-item {
    display: block; /* Show nav items when menu is open */
    margin-top: 10px;
  }

  .auth-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
  }
}
