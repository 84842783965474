footer {
    background-color: #fff; /* Changed background to white */
    color: #222; /* Changed text color to dark for contrast */
    font-size: 14px;
    padding-top: 20px;
}

/* Links */
footer a {
    text-decoration: none;
    color: #444; /* Slightly darker link color */
    transition: color 0.3s ease-in-out;
}

footer a:hover {
    color: #04c2c9;
}

/* Headings */
footer h6 {
    color: #04c2c9;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;
}

/* Social Icons */
footer .social-icons a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin: 0 8px;
    border-radius: 50%;
    background: #eee; /* Light gray background */
    color: #222; /* Dark icon color */
    transition: all 0.3s ease-in-out;
}

footer .social-icons a:hover {
    background: #04c2c9;
    color: #fff;
    transform: translateY(-3px);
}

/* Contact Info */
footer .contact-info p {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 8px;
}

/* Copyright Section */
footer .copyright {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 12px 0;
    font-size: 13px;
    text-align: center;
}
